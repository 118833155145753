/*

乾三连（天），坤六断（地）；
震仰盂（雷），艮覆碗（山）；
离中虚（火），坎中满（水）；
兑上缺（泽），巽下断（风）

*/

export const QIAN = "111"
export const KUN = "000"
export const ZHEN = "100"
export const XUN = "011"
export const LI = "101"
export const KAN = "010"
export const GEN = "001"
export const DUI = "110"
