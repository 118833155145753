import React from "react"
import Layout from "../../../containers/layout"
import Bazhai from "../../../containers/Bazhai"

const Home = () => {
  return (
    <Layout>
      <Bazhai />
    </Layout>
  )
}

export default Home
