import React, { useState } from "react"
import EightMansion from "../../components/EightMansion"
import { getBazhaiChart } from "../../cm-lib/EightMansion/"
import DirectionPicker from "../../components/Picker/Direction"

const Home = ({ onDateChanged, date, handleSearch }) => {
  const [facing, setFacing] = useState("s2")
  return (
    <>
      <h2>8 Mansion</h2>
      <DirectionPicker onChange={setFacing} />
      <br />
      <EightMansion chart={getBazhaiChart(facing)} />
    </>
  )
}

export default Home
