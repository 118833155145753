/*
All Yao unchanged, called Fu Wei
All Yao change, called Yan Nian
Only Upper Yao change, called Sheng Chi
Middle and Lower Yao change, called Tian Yi

Upper and Middle Yao change, called Wu Kwei
Upper and Lower Yao change, called Liu Sha
Only Middle Yao change, called Chueh Ming
Only Lower Yao change, called Huo Hai
*/
import { QIAN, KUN, DUI, ZHEN, LI, KAN, XUN, GEN } from "../constants/NGram"
const map = {
  [KUN]: "SW",
  [QIAN]: "NW",
  [LI]: "S",
  [KAN]: "N",
  [ZHEN]: "E",
  [GEN]: "NE",
  [DUI]: "W",
  [XUN]: "SE",
  SW: KUN,
  NW: QIAN,
  S: LI,
  N: KAN,
  E: ZHEN,
  NE: GEN,
  W: DUI,
  SE: XUN,
}

const flipAtIndex = (bin, indexes) => {
  return bin
    .split("")
    .map((e, i) => {
      if (indexes.includes(i)) {
        return e === "0" ? "1" : "0"
      }
      return e
    })
    .join("")
}

export const getBazhaiChart = direction => {
  const trigram = map[direction.slice(0, -1).toUpperCase()]
  return getEightMansionSectors(trigram)
}

export const getEightMansionSectors = (trigramBinary = "000") => {
  const getSector = (trigram, indexes) => map[flipAtIndex(trigram, indexes)]

  const YanNian = getSector(trigramBinary, [0, 1, 2])
  const FuWei = map[trigramBinary]
  const ShengChi = getSector(trigramBinary, [2])
  const TianYi = getSector(trigramBinary, [0, 1])
  const WuGui = getSector(trigramBinary, [1, 2])
  const LiuSha = getSector(trigramBinary, [0, 2])
  const JueMing = getSector(trigramBinary, [1])
  const HuoHai = getSector(trigramBinary, [0])

  return {
    伏位: FuWei,
    延年: YanNian,
    生气: ShengChi,
    天医: TianYi,
    五鬼: WuGui,
    六煞: LiuSha,
    绝命: JueMing,
    祸害: HuoHai,
    [FuWei]: "伏位",
    [YanNian]: "延年",
    [ShengChi]: "生气",
    [TianYi]: "天医",
    [WuGui]: "五鬼",
    [LiuSha]: "六煞",
    [JueMing]: "绝命",
    [HuoHai]: "祸害",
  }
}
