import React from "react"
import { Text, ResponsiveContext } from "grommet"
import styled from "styled-components"
import { Symbol } from "./Styles"
import { useTranslation } from "react-i18next"

const MountainStar = styled.div`
    position absolute;
    text-align: center;
`

const FlyingStarSquare = ({ index, direction, chart, ...props }) => {
  const size = React.useContext(ResponsiveContext)
  const { t } = useTranslation()
  return (
    <>
      <MountainStar>
        <Text color="white" size="xlarge">
          <div>{chart[direction]} </div>
          <div>{t(chart[direction])} </div>
        </Text>
      </MountainStar>

      <Symbol index={8}>
        <Text color="white" size={size}>
          {direction}
        </Text>
      </Symbol>
    </>
  )
}
export default FlyingStarSquare
