import React from "react"
import { Box } from "grommet"
import styled from "styled-components"
import Square from "../Square"
import QiMen from "../Square/Bazhai"
import { withDirection } from "../../helpers"

const BoxWrapper = styled(Box)`
  width: 32%;
  margin: 2px;
`

const Home = ({ chart }) => {
  return (
    <>
      <Box
        alignSelf="center"
        justify="center"
        direction="row"
        wrap={true}
        fill="horizontal"
      >
        {chart &&
          withDirection(chart).map((e, i) => (
            <BoxWrapper border={true}>
              <Square
                direction={e.direction}
                data={{
                  chart,
                  ...e,
                  index: i,
                }}
                Template={QiMen}
              />
            </BoxWrapper>
          ))}
      </Box>
    </>
  )
}

export default Home
